import { useEffect, useRef } from 'react';
import { handleUserBehavior } from './client';

const useImpressionObserver = (item_id: number, price: number) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) return;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    void (async () => {
                        try {
                            await handleUserBehavior(item_id, 'impression', price);
                            // 2回目は発火させない
                            observer.disconnect();
                        } catch (error) {
                            console.error(`Error handling user behavior for item_id ${item_id}:`, error);
                        }
                    })();
                }
            },
            { threshold: 0.2 }
        );
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [item_id, price]);

    return ref;
};

export default useImpressionObserver;
