'use client';
import { useEffect, useState } from "react";
import { BehaviorType, publishUserBehavior } from "./server";
import { getItem } from "../item/server";

const getServiceId = () => {
    const splittedHostname = location.hostname.split('.');
    return splittedHostname[0] === "www" ? splittedHostname[1] : splittedHostname[0];
}

export const handleUserBehavior = async (product_id: number, behavior_type: BehaviorType, price: number, quantity?: number) => {

    const behavior = {
        product_id,
        service_id: getServiceId(),
        behavior_type,
        behavior_timestamp: new Date().toISOString(),
        display_path: location.pathname,
        price,
    };
    if (!behavior) {
        console.error('customer_id is not found');
        return;
    }

    // Server Actionsは複数同時起動できないので、一つずつ呼び出す
    for (let q = 0; q < (quantity || 1); q++) {
        await publishUserBehavior(behavior, navigator.userAgent);
    }
}

export const handleUserBehaviorWithoutPrice = async (product_id: number, behavior_type: BehaviorType) => {
    const service_id = getServiceId();

    const data = await getItem(service_id, product_id);
    if (!data) {
        console.error('item is not found');
        return;
    }
    const behavior = {
        product_id,
        service_id,
        behavior_type,
        behavior_timestamp: new Date().toISOString(),
        display_path: location.pathname,
        price: data?.item.price,
    };
    if (!behavior) {
        console.error('customer_id is not found');
        return;
    }

    await publishUserBehavior(behavior, navigator.userAgent);
}
//    <ClientBehaviorTracker productId={0} behaviorType={'view'} price={0} />

export function ClientBehaviorTracker(params: { product_id: number, behavior_type: BehaviorType, price: number }) {

    // 1度だけpublishする
    const [published, setPublished] = useState(false);

    useEffect(() => {
        void (async () => {
            if (published || typeof location === 'undefined') {
                return null;
            }
            await publishUserBehavior({
                ...params,
                service_id: getServiceId(),
                behavior_timestamp: new Date().toISOString(),
                display_path: location.pathname,
            }, navigator.userAgent)
            setPublished(true);
        })()
    }, [params, published]);

    return null;
}

if (typeof window !== "undefined") {
    window.handleUserBehavior = handleUserBehavior;
}


export const InnerHtmlImpressionTracker = () => {
    // Use only when rendering HTML using dangerouslySetInnerHTML
    // To use this component, ensure that the elements you want to track have the following attributes:
    // - `class="track-impression"`
    // - `data-item-id="${item.item_id}"`
    // - `data-price="${item.price}"`

    useEffect(() => {
        const elements = document.querySelectorAll('.track-impression');

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const element = entry.target as HTMLElement;
                        const itemId = element.dataset.itemId;
                        const price = element.dataset.price;

                        if (itemId && price) {
                            handleUserBehavior(parseInt(itemId), 'impression', parseFloat(price))
                                .then(() => {
                                    observer.unobserve(element);
                                })
                                .catch((error) => {
                                    console.error(`Error recording impression for ${itemId}:`, error);
                                });
                        }
                    }
                });
            },
            { threshold: 0.2 }
        );

        elements.forEach((element) => observer.observe(element));

        return () => observer.disconnect(); // クリーンアップ
    }, []);

    return null; // UIを持たないコンポーネント
};

