import { sizeType } from '@/lib/size/server'

export const sizeSort = (sizes: sizeType[]) => {
  // xxs, xs, s, m, l, xl, xxl, xxxl, xxxxlの順に並べる
  const sizeOrder = [
    '21.0cm',
    '21.5cm',
    '22.0cm',
    '22.5cm',
    '23.0cm',
    '23.5cm',
    '24.0cm',
    '24.5cm',
    '25.0cm',
    '25.5cm',
    '26.0cm',
    '26.5cm',
    '27.0cm',
    '27.5cm',
    '28.0cm',
    '28.5cm',
    '29.0cm',
    '29.5cm',
    '30.0cm',
    '70',
    '80',
    '90',
    '100',
    '110',
    '120',
    '130',
    '140',
    '150',
    '160',
    'xxs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    'xxl',
    '3xl',
    'xxxl',
    '4xl',
    'xxxxl',
    '5xl',
    'xxxxxl',
    '6xl',
  ]
  return sizes.sort(
    (a, b) =>
      sizeOrder.indexOf(a.name.trim().toLocaleLowerCase()) -
      sizeOrder.indexOf(b.name.trim().toLocaleLowerCase()),
  )
}

// サイズを表示用にフォーマットする（表記揺れの防止）
export const formatSizeToDisplay = (size: sizeType) => {
  if (size.sex === '') {
    return size.name
  } else {
    return `${size.name}(${size.sex})`
  }
}
